<template>
  <div>
    <br />
    <div class="panel">
      <div class="bootstrap">
        <b-table
          :sort-by="'ownerName'"
          :sticky-header="'100%'"
          :no-border-collapse="true"
          responsive="sm"
          hover
          :show-empty="true"
          empty-text="Loading..."
          :fields="tableFields"
          head-variant="light"
          sort-icon-left
          :items="cashCheck.transactions"
        >
        </b-table>
      </div>
      <br />
      <div class="totals">
        <div>
          <h3>Totals:</h3>
        </div>
      </div>
      <div class="money">
        <div><b>Cash:</b> ${{ cashCheck.totals.cash }}</div>
        <div><b>Check:</b> ${{ cashCheck.totals.check }}</div>
        <div>
          <b>Cash+Check:</b> ${{
            cashCheck.totals.check + cashCheck.totals.cash
          }}
        </div>
        <div><b>Credit Card:</b> ${{ cashCheck.totals.creditCard }}</div>
        <br />
        <div><b>Donor:</b> ${{ cashCheck.totals.donorFee }}</div>
        <div>
          <b>Helping School:</b> ${{ cashCheck.totals.helpingSchoolFee }}
        </div>
        <div><b>Mail:</b> ${{ cashCheck.totals.mailFee }}</div>
      </div>
    </div>
  </div>
</template>

<script>
const cashCheckJSON = require("@/assets/testData/cashCheck.json");
export default {
  data() {
    return {
      cashCheck: cashCheckJSON,
      tableFields: [
        {
          key: "ownerName",
          sortable: true
        },
        {
          key: "actionType",
          sortable: true
        },
        {
          key: "plate",
          sortable: true
        },
        {
          key: "class",
          sortable: true
        },
        {
          key: "totalCash",
          sortable: true
        },
        {
          key: "totalCheck",
          sortable: true
        },
        {
          key: "checkNumber",
          sortable: true
        },
        {
          key: "totalCreditCard",
          sortable: true
        },
        {
          key: "inHouse",
          sortable: true
        },
        {
          key: "online",
          sortable: true
        },
        {
          key: "creditCard",
          label: "Processing Fee",
          sortable: true
        },
        {
          key: "clerk",
          sortable: true
        },
        {
          key: "drawer",
          sortable: true
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.totals {
  display: flex;
  margin-left: 75%;
  width: 25%;
}
.money {
  margin-left: 80%;
  width: 20%;
}
.money div {
  white-space: nowrap;
}
</style>
>
